/**
 * LoginProvider
 *
 * Usage:
 *
 * To use the default LoginForm component:
 * <LoginProvider disableMeta />
 *
 * To use a custom LoginForm component, and optionally customise form label(s):
 * <LoginProvider disableMeta>
 *   <LoginForm formLabels={{ labelUserName: 'Email' }} />
 * </LoginProvider>
 *
 * disableMeta prop can be used to disable Helmet from applying meta tags. This
 * is useful if this component is included on a page (or in the header) and not
 * in its own page container.
 */

// Core imports
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Style, SEO and settings
import Helmet from 'react-helmet';
import { withTheme } from '@smooth-ui/core-sc';

// Component features
import injectReducer from '../../utils/injectReducer'; // _platform
import injectSaga from '../../utils/injectSaga'; // _platform
import { loginRequest } from './actions';
import { selectStatus } from './selectors';
import reducer from './reducer';
import saga from './saga';
import {
  selectCurrentUser,
  selectCurrentUserTemp,
  selectUserOptions,
} from '../../containers/App/selectors'; // _platform

import history from '../../utils/history'; // _platform
import LoadAsync from '../../utils/LoadAsync'; // _platform
const LoginForm = LoadAsync(
  () =>
    import(
      /* webpackChunkName: "loginForm" */ '../../components/LoginForm/LoginForm'
    ) // _platform
);

const ChangePasswordProvider = LoadAsync(
  () =>
    import(
      /* webpackChunkName: "changePasswordProvider" */ '../../containers/ChangePasswordProvider/ChangePasswordProvider'
    ) // _platform
);

const ProfileProvider = LoadAsync(
  () =>
    import(
      /* webpackChunkName: "profileProvider" */ '../../containers/ProfileProvider/ProfileProvider'
    ) // _platform
);

const key = 'login';

const LoginProvider = props => {
  const lpRef = useRef(null);
  useEffect(() => {
    // Scroll to the container, not needed for Firefox but is needed for Chrome/Safari
    if (props.location.hash === '#login') {
      const timer1 = setTimeout(() => {
        lpRef.current.scrollIntoView();
      }, 250); // Arbitrary value to give the app enough time to render everything even on slow connections

      return () => {
        clearTimeout(timer1);
      };
    }
  }, [props.location.hash]);

  const {
    currentUser,
    currentUserTemp,
    userOptions,
    theme: { settingsApp = {} },
  } = props;

  // Check if the user is logged in, or temporarily logged in.
  // If they are, hide the form.
  const userLoggedIn = !!currentUser.token || !!currentUserTemp.token || false;

  let initialValues = {};
  if (
    // Skip Remember Me if sessionStorage is in use
    !!settingsApp.loginRememberMeEnabled &&
    !settingsApp.loginUseSessionStorage
  ) {
    initialValues = {
      rememberLogin: true,
    };
  }

  return (
    <div className="login-page" id="login" ref={lpRef}>
      {!props.disableMeta && (
        <Helmet>
          <title>Log in</title>
          <meta
            name="description"
            content={`Log into your ${settingsApp.siteName} account`}
          />
        </Helmet>
      )}
      {!userLoggedIn &&
        (props.children ? (
          React.Children.map(props.children, childElement =>
            React.cloneElement(childElement, { ...props, initialValues })
          )
        ) : (
          <LoginForm {...props} initialValues={initialValues} />
        ))}

      {!!userLoggedIn &&
        !currentUser.token &&
        userOptions.forcePasswordChange && (
          <ChangePasswordProvider
            initialValues={{ oldPassword: userOptions.oldPassword }}
          />
        )}

      {/* The user is logged in, but their token is not in currentUser: their profile is incomplete */}
      {!!userLoggedIn &&
        !currentUser.token &&
        history.location.pathname !== settingsApp.redirectOnIncompleteProfile &&
        !userOptions.forcePasswordChange &&
        !userOptions.isProfileValid && <ProfileProvider />}
    </div>
  );
};
LoginProvider.propTypes = {
  children: PropTypes.element,
  currentUser: PropTypes.object.isRequired,
  currentUserTemp: PropTypes.object.isRequired,
  disableMeta: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  status: PropTypes.object.isRequired,
  theme: PropTypes.object,
  userOptions: PropTypes.object.isRequired,
};

LoginProvider.defaultProps = {
  children: undefined,
  disableMeta: false,
  theme: { settingsApp: {} },
};

function mapDispatchToProps(dispatch, props) {
  const settingsApp = (props.theme && props.theme.settingsApp) || {};
  return {
    onSubmitForm: values => dispatch(loginRequest(values, settingsApp)),
    dispatch,
  };
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser(),
  currentUserTemp: selectCurrentUserTemp(),
  status: selectStatus(),
  userOptions: selectUserOptions(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });

export default compose(
  withRouter,
  withTheme,
  withReducer,
  withSaga,
  withConnect
)(LoginProvider);
