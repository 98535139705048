/**
 * Login actions
 */

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR } from './constants';

/**
 * Login request - start the request saga
 *
 * @returns {object} An action object with a type of LOGIN_REQUEST
 */
export function loginRequest(values, settingsApp) {
  return {
    type: LOGIN_REQUEST,
    values,
    settingsApp,
  };
}

/**
 * Dispatched when loginRequest succeeds by the request saga
 *
 * @param {object} response The response
 * @returns {object}         An action object with a type of LOGIN_SUCCESS and the response
 */
export function loginSuccess(response) {
  return {
    type: LOGIN_SUCCESS,
    response,
  };
}

/**
 * Dispatched when loginRequests fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of LOGIN_ERROR and the error
 */
export function loginError(error) {
  return {
    type: LOGIN_ERROR,
    error,
  };
}
